import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const Contact = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(limit: 1, filter: { wordpress_id: { eq: 15 } }) {
        edges {
          node {
            title
            status
            content
            wordpress_id
            featured_media {
              source_url
            }
          }
        }
      }
    }
  `)
  return (
    <Layout
      featuredImg={
        data.allWordpressPage.edges[0].node.featured_media.source_url
      }
      pageName={data.allWordpressPage.edges[0].node.title}
    >
      <section className="py-4 w-full md:w-11/12 lg:w-8/12 mx-auto flex flex-col md:flex-row">
        <div className="w-full md:w-10/12 lg:py-0 mx-auto">
          <div
            dangerouslySetInnerHTML={{
              __html: data.allWordpressPage.edges[0].node.content,
            }}
          ></div>
        </div>
        {/* <div className="w-full md:w-6/12 py-4 lg:py-4">
        <div className="iframe-responsive">
          <iframe
            className="border-none"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20143.052754130673!2d0.5351316256759953!3d50.8703917623136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47df10762f0aad29%3A0xd6cf7e8d3cc7a46a!2s10%20Adelaide%20Rd%2C%20Hastings%2C%20Saint%20Leonards-on-sea%20TN38%209DA!5e0!3m2!1sen!2suk!4v1585659611148!5m2!1sen!2suk"
            width="600"
            height="450"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
          </div>
        </div> */}
      </section>
      <Seo title={data.allWordpressPage.edges[0].node.title} description={data.allWordpressPage.edges[0].node.content} image={data.allWordpressPage.edges[0].node.featured_media.source_url} article={true} />
    </Layout>
  )
}

export default Contact
